<template>
  <div>
    <div class="ll-box">
        <!-- breadcrumb -->
        <div class="breadcrumb-top">
            <b-breadcrumb class="">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                <feather-icon
                    icon="HomeIcon"
                    size="16"
                />
                </b-breadcrumb-item>
                <b-breadcrumb-item>
                {{ $t('Delivery Order') }}
                </b-breadcrumb-item>
                <b-breadcrumb-item active >
                {{ $t('Create Mapping') }}
                </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
          <!-- back button -->
            <b-button
              variant="flat-secondary"
              class="ll-cancel"
              :to="{ name: 'PY-Waybill' }"
            >
              <b-img
                :src="require('@/assets/images/imgs/return.png')"
                height="auto"
                class="mr-1"
              />
            </b-button>
    </div>
    <b-card class="ll-card">
      <b-card-body>
          <b-row >
            <b-col lg="8" class="">
                <b-row >
                    <b-col lg="12" >
                        <b-form-group
                        :label="$t('Customer')"
                        label-for=""
                        >
                        <v-select
                            id="customer"
                            :options="customerList"
                            v-model="company"
                            @input="getPyUsage(company)"
                            label="info"
                            class="ll-cutselect"
                            ref="company"
                            placeholder="Select Customer"
                        />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="12">
                        <div class="scanBox" :style="bg">
                        <feather-icon
                            :icon="icon"
                            size="156"
                        />
                        <span>{{text}}</span>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6">
                        <b-form-group
                        :label="$t('From')"
                        label-for=""
                        class="ipt"
                        >
                        <b-form-input size="ll"
                            v-model="info.from"
                            ref="from"
                            type="number"
                            :disabled="fromDis"
                            @keyup.enter="submit"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col lg="6">
                        <b-form-group
                        :label="$t('To')"
                        label-for=""
                        class="ipt"
                        >
                        <b-form-input size="ll"
                            v-model="info.to"
                            type="number"
                            ref="to"
                            :disabled="toDis"
                            @keyup.enter="submit"
                        />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
            <b-col lg="4" class="">
                <el-table
                    :row-style="{ height: '20px' }"
                    :cell-style="{ fontSize: '14px', padding: '3px 0' }"
                    :data="items"
                    empty-text="No matching records found"
                    >
                    <el-table-column
                    prop="time"
                    fixed="left"
                    label="Create Time"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="from"
                    fixed="left"
                    :label="$t('From')"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="to"
                    fixed="left"
                    :label="$t('To')"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="result"
                    fixed="left"
                    label="Result"
                    width="80"
                    >
                    </el-table-column>
                </el-table>
            </b-col>
          </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormSelect,
  BFormInput,
  BButton,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BSidebar,
  BTable,
  BInputGroup,
  BBreadcrumb,
  BBreadcrumbItem,
  BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BSidebar,
    vSelect,
    BTable,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    BImg,
  },
  data() {
    return {
      customerList:[],
      customer:'',
      items:[],
      icon:'AlertCircleIcon',
      bgColor: '#ff9f43',
      text:'Please enter the PY-Waybill number for mapping',
      info:{
        to:'',
        from:'',
        company:''
      },
      company:'',
      fromDis:false,
      toDis:false,
    }
  },
  computed: {
    bg() {
      return {
        '--bgColor': this.bgColor
      }
    }
  },
  created() {
  },
  mounted() {
    this.queryCustomer()
  },
  methods: {
    queryCustomer() {
       this.$http.get('/admin/settings/organization/findAllOrganization').then(res => {
         this.customerList = res.data.data.map(res=>{
          let arr = {id:res.id,orgCode:res.orgCode, info:res.orgCode+' - '+res.displayName+' - '+res.keAccountNumber,name:res.name}
          return arr
        })

      })
    },
    getPyUsage(company) {
      this.info.orgID = company.orgCode
    },
    submit(){
      if(!this.info.from || this.info.from.length < 10){
        this.text = this.info.from+'不是有效的單號，單號長度最少必須為10位...'
        this.bgColor = '#ea5455'
        this.icon = 'XCircleIcon'
        this.$refs.from.focus()
        return;
      }
      if(!this.info.to || this.info.to.length < 10){
        this.text = this.info.to+'不是有效的單號，單號長度最少必須為10位...'
        this.bgColor = '#ea5455'
        this.icon = 'XCircleIcon'
        this.$refs.to.focus()
        return;
      }
       if(parseInt(this.info.from) > parseInt(this.info.to)){
         this.text = 'The "From" Value is larger than "To" Value. Please check again.'
          this.bgColor = '#ea5455'
          this.icon = 'XCircleIcon'
          this.$refs.from.focus()
          return;
       }
      if(!this.company){
        this.$refs.company.select()
        this.$refs.company.focus()
        return;
      }
      let where = ""
      let params = this.info
      for(var k in params){
          where +=k+"="+params[k] + "&"
      }
      this.fromDis=true,
      this.toDis=true,
      this.$http.get(`/py/preOrder?${where}`).then(res => {
        if (res.data.code == 200) {
          this.text = res.data.message
          this.bgColor = '#28c76f'
          this.icon = 'CheckCircleIcon'
        }else{
          this.text = res.data.message
          this.bgColor = '#ea5455'
          this.icon = 'XCircleIcon'
        }
          const obj = {}
          const fromDate = new Date()
          obj.time = fromDate.getFullYear() + "-" +
          (fromDate.getMonth()> 8 ? (fromDate.getMonth() + 1) : "0" + (fromDate.getMonth() + 1)) + "-"
          + (fromDate.getDate()> 9 ? (fromDate.getDate()) : "0" + (fromDate.getDate()))
          + ' ' + (fromDate.getHours()>9 ? (fromDate.getHours()) : '0'+(fromDate.getHours())) + ':'
          + (fromDate.getMinutes()>9 ? (fromDate.getMinutes()) : '0'+(fromDate.getMinutes()))
          + ':' + (fromDate.getSeconds()>9 ? (fromDate.getSeconds()) : '0'+(fromDate.getSeconds()))
          obj.from = this.info.from
          obj.to = this.info.to
          obj.result = (res.data.code == 200 ? 'success' : 'failed')
          this.items.unshift(obj)
          this.fromDis=false,
          this.toDis=false,
          this.info.from = ''
          this.info.to = ''
          this.$refs.from.focus()

      })
    },
  },
}
</script>
<style scoped>
.scanBox{
  width: 100%;
  height: 50vh;
  background: var(--bgColor);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 56px;
}
.ll-brow{
  margin-top: 20px;
  margin-bottom: 20px;
}
.ll-card {
  font-size: 30px !important;
  font-weight: bold;
}

</style>
<style>
[dir=ltr] .ll-cutselect .vs__dropdown-toggle {
  border: 5px solid;
}
[dir] .ipt .form-control:focus{
  border-color: #ed6d01;
}
[dir] .ipt .form-control{
    font-size: 40px;
  font-weight: bold;
  height: 7rem;
  border: 5px solid;
}
</style>
